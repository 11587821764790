// @ts-nocheck
import TIMSdk, { Conversation } from '@tencentcloud/chat';
import TimUploadPlugin from 'tim-upload-plugin';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import isError from 'lodash/isError';
import { getLogger } from '../common/Logger';
import { TcccSdk } from '../tccc';
import { getPlainMessage, PickPlainMessageKey } from './tim.utils';
import { getSessionIdFromGroupId } from '../store/sessions/tim.thunk';
import { CUSTOM_MESSAGE_SRC } from '../constants/im';
import { mediaEndedHandler, mediaRejectHandler, mediaStartHandler } from '../socket/s2cHandler';
import i18next from '../i18n/i18next.config';
import { isLocal } from 'tccc-env/src/env';

type MessageWithSessoinId = {
  sessionId: string;
};
type PlainMessage<T extends TIMSdk.Message> = Pick<T, PickPlainMessageKey> & MessageWithSessoinId;
export type TextMessage = PlainMessage<TIMSdk.TextPayload>;
export type ImageMessage = PlainMessage<TIMSdk.ImagePayload>;
export type AudioMessage = PlainMessage<TIMSdk.AudioPayload>;
export type VideoMessage = PlainMessage<TIMSdk.VideoPayload>;
export type FileMessage = PlainMessage<TIMSdk.FilePayload>;
export type FaceMessage = PlainMessage<TIMSdk.FacePayload>;
export type CustomMessage = PlainMessage<TIMSdk.CustomPayload>;
export type LocationMessage = PlainMessage<TIMSdk.LocationPayload>;
export type GroupTipsMessage = PlainMessage<TIMSdk.GroupTipPayload>;
export type GroupSystemNoticeMessage = PlainMessage<TIMSdk.GroupSystemNoticePayload>;

export type CreateMessageParams<T extends keyof TIMSdk.SDK> = Parameters<TIMSdk.SDK[T]>[0] & MessageWithSessoinId;
export type CreateTextMessageParams = CreateMessageParams<'createTextMessage'>;
export type CreateImageMessageParams = CreateMessageParams<'createImageMessage'>;
export type CreateAudioMessageParams = CreateMessageParams<'createAudioMessage'>;
export type CreateVideoMessageParams = CreateMessageParams<'createVideoMessage'>;
export type CreateFileMessageParams = CreateMessageParams<'createFileMessage'>;
export type CreateFaceMessageParams = CreateMessageParams<'createFaceMessage'>;
export type CreateCustomMessageParams = CreateMessageParams<'createCustomMessage'>;
export type CreateLocationMessageParams = CreateMessageParams<'createLocationMessage'>;

export type Message =
  | TextMessage
  | ImageMessage
  | AudioMessage
  | FileMessage
  | FaceMessage
  | CustomMessage
  | VideoMessage
  | LocationMessage
  | GroupTipsMessage
  | GroupSystemNoticeMessage;
export class TIM {
  private tim?: TIMSdk.SDK;
  private emitter: TcccSdk;
  private sdkAppId: string;
  private userId: string;
  private logger: ReturnType<typeof getLogger>;
  private sdkLogout: boolean;
  private sdkLogin: boolean;
  private originMessageMap: Map<string, TIMSdk.Message>;
  private readyResolver: ((sdk: TIMSdk.SDK) => void)[];
  private cancelTypingStateChangedDebounce = debounce(({ sessionId }: { sessionId: string }) => {
    this.emitter.emit('typingStateChanged', { sessionId, state: false });
  }, 5000);

  private MAX_MESSAGE_LIST_COUNT = 30;

  constructor(emitter: TcccSdk) {
    if (!emitter.Agent.userInfo) {
      throw new Error('Authorization failed');
    }
    this.sdkAppId = emitter.Agent.userInfo.sdkAppId;
    this.userId = emitter.Agent.userInfo.userId;
    this.logger = getLogger({ sdkAppId: this.sdkAppId, userId: this.userId });
    this.sdkLogout = false;
    this.sdkLogin = false;
    this.sdkLogging = false;
    this.emitter = emitter;
    this.readyResolver = [];
    this.originMessageMap = new Map<string, TIMSdk.Message>([]);
  }

  get timSdk(): Promise<TIMSdk.SDK> {
    return new Promise((resolve) => {
      if (this.tim) {
        return resolve(this.tim);
      }
      this.readyResolver.push((timSdk: TIMSdk.SDK) => {
        resolve(timSdk);
      });
    });
  }

  public login({ sdkAppId, userId, userSig }: { sdkAppId: string; userId: string; userSig: string }) {
    localStorage.removeItem(`TIM_${sdkAppId}_${userId}_conversationMap`);
    return new Promise((resolve, reject) => {
      this.logger.debug('[TIM] create tim sdk');
      if (this.sdkLogging) {
        this.logger.info('sdk Logging');
        return resolve(true);
      }
      this.sdkLogging = true;
      if (this.sdkLogin && this.tim) {
        this.logger.info('sdk already login');
        this.sdkLogging = false;
        return resolve(true);
      }
      this.sdkLogin = true;
      this.sdkLogout = false;
      const timInstance = TIMSdk.create({ SDKAppID: +sdkAppId });
      timInstance.registerPlugin({ 'tim-upload-plugin': TimUploadPlugin });
      timInstance.setLogLevel(4);

      const TIMSdkReadyCallback = () => {
        if (this.sdkLogout) {
          this.logout();
          this.sdkLogging = false;
          return reject(false);
        }
        this.logger.debug('[TIM] sdk ready');
        timInstance.callExperimentalAPI('disableMessagePullOnInvite');
        timInstance.off(TIMSdk.EVENT.SDK_READY, TIMSdkReadyCallback);
        this.tim = timInstance;
        this.emitter.emit('IMNetStateChange', { state: 'connected' });
        this.sdkLogging = false;
        this.readyResolver.forEach((cb) => cb(timInstance));
        resolve(true);
      };
      timInstance.on(TIMSdk.EVENT.SDK_READY, TIMSdkReadyCallback, this);
      timInstance.on(TIMSdk.EVENT.ERROR, this[TIMSdk.EVENT.ERROR], this);
      timInstance.on(TIMSdk.EVENT.NET_STATE_CHANGE, this[TIMSdk.EVENT.NET_STATE_CHANGE], this);
      timInstance.on(TIMSdk.EVENT.MESSAGE_RECEIVED, this[TIMSdk.EVENT.MESSAGE_RECEIVED], this);
      timInstance.on(TIMSdk.EVENT.MESSAGE_REVOKED, this[TIMSdk.EVENT.MESSAGE_REVOKED], this);
      timInstance.on(TIMSdk.EVENT.MESSAGE_MODIFIED, this[TIMSdk.EVENT.MESSAGE_MODIFIED], this);
      timInstance.on(TIMSdk.EVENT.CONVERSATION_LIST_UPDATED, this[TIMSdk.EVENT.CONVERSATION_LIST_UPDATED], this);
      timInstance.on(
        TIMSdk.EVENT.MESSAGE_READ_RECEIPT_RECEIVED,
        this[TIMSdk.EVENT.MESSAGE_READ_RECEIPT_RECEIVED],
        this,
      );
      timInstance
        .login({ userID: userId, userSig })
        .then(() => {
          this.logger.debug('[TIM] login success');
        })
        .catch((err) => {
          this.logger.custom(
            {
              userId,
              userSig,
              message: err.message,
              level: 'error',
            },
            '[TIM]login failed',
          );
          this.sdkLogging = false;
          reject(err);
        });
    });
  }

  public logout() {
    if (!this.sdkLogin) {
      this.tim = undefined;
      this.readyResolver = [];
      this.sdkLogout = true;
      this.sdkLogin = false;
      this.logger.info('tim sdk not logged in');
      this.sdkLogging = false;
      return;
    }
    if (this.sdkLogout) {
      this.logger.info('tim sdk already logout');
      this.sdkLogging = false;
      return;
    }
    this.timSdk
      .then((sdk) => {
        sdk.off(TIMSdk.EVENT.ERROR, this[TIMSdk.EVENT.ERROR], this);
        sdk.off(TIMSdk.EVENT.NET_STATE_CHANGE, this[TIMSdk.EVENT.NET_STATE_CHANGE], this);
        sdk.off(TIMSdk.EVENT.KICKED_OUT, this[TIMSdk.EVENT.KICKED_OUT], this);
        sdk.off(TIMSdk.EVENT.MESSAGE_RECEIVED, this[TIMSdk.EVENT.MESSAGE_RECEIVED], this);
        sdk.off(TIMSdk.EVENT.MESSAGE_MODIFIED, this[TIMSdk.EVENT.MESSAGE_MODIFIED], this);
        sdk.off(TIMSdk.EVENT.CONVERSATION_LIST_UPDATED, this[TIMSdk.EVENT.CONVERSATION_LIST_UPDATED], this);
        sdk.off(TIMSdk.EVENT.MESSAGE_READ_RECEIPT_RECEIVED, this[TIMSdk.EVENT.MESSAGE_READ_RECEIPT_RECEIVED], this);
        return sdk.logout();
      })
      .catch((e) => {
        this.sdkLogging = false;
        this.logger.error('logout failed', e);
      })
      .finally(() => {
        this.tim = undefined;
        this.readyResolver = [];
        this.sdkLogout = true;
        this.sdkLogin = false;
        this.sdkLogging = false;
      });
  }

  public async sendMessage(message: Message, options?: { onlineUserOnly: boolean }) {
    const sdk = await this.timSdk;
    const originMessage = this.originMessageMap.get(message.ID);
    if (!originMessage) {
      throw new Error(i18next.t('Message does not exist'));
    }
    this.originMessageMap.delete(message.ID);
    const response = await sdk.sendMessage(originMessage, options);
    return {
      code: response.code,
      data: {
        message: this.transformOriginMessage(response.data.message, message.sessionId),
      },
    };
  }

  public async resendMessage(message: Message) {
    const sdk = await this.timSdk;
    const originMessage = (await this.timSdk).findMessage(message.ID);
    if (!originMessage) {
      throw new Error('Message does not exist');
    }
    if (originMessage.status !== 'fail') {
      throw new Error('');
    }
    const response = await sdk.resendMessage(originMessage);
    return {
      code: response.code,
      data: {
        message: this.transformOriginMessage(response.data.message, message.sessionId),
      },
    };
  }

  public async revokeMessage<T extends Message>(message: T) {
    const sdk = await this.timSdk;
    const originMessage = (await this.timSdk).findMessage(message.ID);
    if (!originMessage) {
      throw new Error('Message does not exist');
    }
    const response = await sdk.revokeMessage(originMessage);
    return {
      code: response.code,
      data: {
        message: this.transformOriginMessage(response.data.message, message.sessionId),
      },
    };
  }

  public async markUnread(conversationID: string) {
    const sdk = await this.timSdk;
    const res = await sdk.markConversation({
      conversationIDList: [conversationID],
      markType: TIMSdk.TYPES.CONV_MARK_TYPE_UNREAD,
      enableMark: true,
    });
    return res;
  }

  public async setMessageRead(...args: Parameters<TIMSdk.SDK['setMessageRead']>) {
    const sdk = await this.timSdk;
    const res = await sdk.setMessageRead(...args);
    await sdk.markConversation({
      conversationIDList: [args[0].conversationID],
      markType: TIMSdk.TYPES.CONV_MARK_TYPE_UNREAD,
      enableMark: false,
    });
    return res;
  }

  public async findMessage(Id: string) {
    const sdk = await this.timSdk;
    const res = await sdk.findMessage(Id);
    return res;
  }

  public async sendMessageReadReceipt(...args: Parameters<TIMSdk.SDK['sendMessageReadReceipt']>) {
    const sdk = await this.timSdk;
    const res = await sdk.sendMessageReadReceipt(...args);
    return res;
  }

  public async getMessageReadReceiptList(...args: Parameters<TIMSdk.SDK['getMessageReadReceiptList']>) {
    const sdk = await this.timSdk;
    const res = await sdk.getMessageReadReceiptList(...args);
    return res;
  }

  public async createTextMessage(args: CreateTextMessageParams): Promise<TextMessage> {
    const { sessionId, ...params } = args;
    const sdk = await this.timSdk;
    const message = sdk.createTextMessage(params);
    const plainMessage = this.transformOriginMessage(message, sessionId);
    this.originMessageMap.set(plainMessage.ID, message);
    return plainMessage;
  }

  public async createImageMessage(args: CreateImageMessageParams): Promise<ImageMessage> {
    const { sessionId, ...params } = args;
    const sdk = await this.timSdk;
    const message = sdk.createImageMessage(params);
    const plainMessage = this.transformOriginMessage(message, sessionId);
    this.originMessageMap.set(plainMessage.ID, message);
    return plainMessage;
  }

  public async createFileMessage(args: CreateFileMessageParams): Promise<FileMessage> {
    const { sessionId, ...params } = args;
    const sdk = await this.timSdk;
    const message = sdk.createFileMessage(params);
    const plainMessage = this.transformOriginMessage(message, sessionId);
    this.originMessageMap.set(plainMessage.ID, message);
    return plainMessage;
  }

  public async createAudioMessage(args: CreateAudioMessageParams): Promise<AudioMessage> {
    const { sessionId, ...params } = args;
    const sdk = await this.timSdk;
    const message = sdk.createAudioMessage(params);
    const plainMessage = this.transformOriginMessage(message, sessionId);
    this.originMessageMap.set(plainMessage.ID, message);
    return plainMessage;
  }

  public async createVideoMessage(args: CreateVideoMessageParams): Promise<VideoMessage> {
    const { sessionId, ...params } = args;
    const sdk = await this.timSdk;
    const message = sdk.createVideoMessage(params);
    const plainMessage = this.transformOriginMessage(message, sessionId);
    this.originMessageMap.set(plainMessage.ID, message);
    return plainMessage;
  }

  public async createCustomMessage(args: CreateCustomMessageParams): Promise<CustomMessage> {
    const { sessionId, ...params } = args;
    const sdk = await this.timSdk;
    const message = sdk.createCustomMessage(params);
    const plainMessage = this.transformOriginMessage(message, sessionId);
    this.originMessageMap.set(plainMessage.ID, message);
    return plainMessage;
  }

  public async createFaceMessage(args: CreateFaceMessageParams): Promise<FaceMessage> {
    const { sessionId, ...params } = args;
    const sdk = await this.timSdk;
    const message = sdk.createFaceMessage(params);
    const plainMessage = this.transformOriginMessage(message, sessionId);
    this.originMessageMap.set(plainMessage.ID, message);
    return plainMessage;
  }
  public async createLocationMessage(args: CreateLocationMessageParams): Promise<LocationMessage> {
    const { sessionId, ...params } = args;
    const sdk = await this.timSdk;
    const message = sdk.createLocationMessage(params);
    const plainMessage = this.transformOriginMessage(message, sessionId);
    this.originMessageMap.set(plainMessage.ID, message);
    return plainMessage;
  }

  public async translateText(args: Parameters<TIMSdk.SDK['translateText']>[0]): Array<string> {
    const sdk = await this.timSdk;
    const translateRes = await sdk.translateText(args);
    const { translatedTextList } = translateRes.data;
    return translatedTextList;
  }

  public async getGroupProfile(args: { groupID: string }) {
    const sdk = await this.timSdk;
    const groupProfileRes = await sdk.getGroupProfile(args);
    return groupProfileRes;
  }

  public async getGroupMemberList(args: { groupID: string; count: number; offset: number }) {
    const sdk = await this.timSdk;
    const groupMemberListRes = await sdk.getGroupMemberList(args);
    return groupMemberListRes;
  }

  public async getGroupMemberProfile(args: { groupID: string; userIDList: string[] }) {
    const sdk = await this.timSdk;
    const groupMemberProfileRes = await sdk.getGroupMemberProfile(args);
    return groupMemberProfileRes;
  }

  public getMessageList = async (
    args: Parameters<TIMSdk.SDK['getMessageList']>[0] & MessageWithSessoinId,
  ): Promise<{
    code: number;
    data: {
      messageList: Message[];
      nextReqMessageID: string;
      isCompleted: boolean;
    };
  }> => {
    const sdk = await this.timSdk;
    let count = this.MAX_MESSAGE_LIST_COUNT;
    if (args.count && args.count < this.MAX_MESSAGE_LIST_COUNT) {
      count = args.count;
    }
    const messageList: Message[] = [];
    let tempRes = await this.innerGetMessageList(sdk, { ...args, count });
    if (tempRes.code === 0) {
      messageList.unshift(...tempRes.data.messageList);
    }
    /**
     * 1. 消息列表包括了隐藏的自定义消息, 需要过滤并且返回真实数量
     * 2. IM SDK最大支持15数量，tccc-sdk支持最大30
     */
    while (
      messageList.length > 0 &&
      messageList.length < count &&
      !tempRes.data.isCompleted &&
      tempRes.data.nextReqMessageID
    ) {
      const tempArgs = {
        ...args,
        count: 5,
        nextReqMessageID: tempRes.data.nextReqMessageID,
      };
      const prevRes = tempRes;
      tempRes = await this.innerGetMessageList(sdk, tempArgs);
      if (prevRes.data.nextReqMessageID === tempRes.data.nextReqMessageID) {
        this.logger.warn('getMessageList: get same messageList', tempRes.data);
        break;
      }
      if (tempRes.code === 0) {
        messageList.unshift(...tempRes.data.messageList);
      }
    }
    const response = {
      code: tempRes.code,
      data: {
        ...tempRes.data,
        messageList,
      },
    };
    this.logger.info('getMessageList response', `length: ${response.data.messageList.length}`, response);
    return response;
  };

  // 解决react18渲染组件重复调用effect导致sdk的返回值有问题（web sdk的逻辑不保证getmessagelist的返回值幂等）
  public getMessageListV2 = async (
    args: Parameters<TIMSdk.SDK['getMessageListHopping']>[0] & MessageWithSessoinId,
  ): Promise<{
    code: number;
    data: {
      messageList: Message[];
      nextMessageSeq?: string;
      nextMessageTime?: number;
      isCompleted: boolean;
    };
  }> => {
    const sdk = await this.timSdk;
    const count = this.MAX_MESSAGE_LIST_COUNT;
    const messageList: Message[] = [];
    let tempRes = await this.innerGetMessageListV2(sdk, { ...args, count: 15 });

    if (tempRes.code === 0) {
      messageList.unshift(...tempRes.data.messageList);
    }
    /**
     * 1. 消息列表包括了隐藏的自定义消息, 需要过滤并且返回真实数量
     * 2. IM SDK最大支持15数量，tccc-sdk支持最大30
     */
    while (
      messageList.length > 0 &&
      messageList.length < count &&
      !tempRes.data.isCompleted &&
      (tempRes.data.nextMessageSeq || tempRes.data.nextMessageTime)
    ) {
      const tempArgs = {
        ...args,
        count: 15,
        sequence: tempRes?.data?.nextMessageSeq,
        time: tempRes?.data?.nextMessageTime,
      };
      const prevRes = tempRes;
      tempRes = await this.innerGetMessageListV2(sdk, tempArgs);
      if (prevRes.data.nextMessageSeq === tempRes.data.nextMessageSeq) {
        this.logger.warn('getMessageList: get same messageList', tempRes.data);
        break;
      }
      if (tempRes.code === 0) {
        messageList.unshift(...tempRes.data.messageList);
      }
    }
    const response = {
      code: tempRes.code,
      data: {
        ...tempRes.data,
        messageList,
      },
    };
    this.logger.info('getMessageList response', `length: ${response.data.messageList.length}`, response);
    return response;
  };

  public getConversationProfile = async (
    conversationID,
  ): Promise<{
    code: number;
    data: {
      conversation: Conversation;
    };
  }> => {
    const sdk = await this.timSdk;
    const response = await sdk.getConversationProfile(conversationID);
    return response;
  };

  public async getConversationList(options: undefined | { type: string } = undefined) {
    const sdk = await this.timSdk;
    const res = await sdk.getConversationList(options);
    return {
      code: res.code,
      data: {
        conversationList: res.data.conversationList
          .filter((conv) => conv.type !== TIMSdk.TYPES.CONV_SYSTEM)
          .map((conv) => ({
            ...conv,
            lastMessage: {
              ...this.transformOriginMessage(conv.lastMessage, conv.conversationID),
              lastTime: conv.lastMessage.lastTime,
            },
          })),
      },
    };
  }

  public async getUserProfile(...params: Parameters<TIMSdk.SDK['getUserProfile']>) {
    const sdk = await this.timSdk;
    const res = await sdk.getUserProfile(...params);
    return res;
  }

  [TIMSdk.EVENT.ERROR](event: any) {
    this.logger.error(TIMSdk.EVENT.ERROR, event.data);
  }

  [TIMSdk.EVENT.KICKED_OUT](event: any) {
    this.logger.error(TIMSdk.EVENT.KICKED_OUT, event.data);
  }

  [TIMSdk.EVENT.NET_STATE_CHANGE](event: { data: { state: string } }) {
    // NET_STATE_CONNECTED: 'connected;';
    // NET_STATE_CONNECTING: 'connecting';
    // NET_STATE_DISCONNECTED: 'disconnected';
    if (event?.data?.state === 'disconnected' || event?.data?.state === 'connecting') {
      this.emitter.emit('IMNetStateChange', { state: 'reconnecting' });
    }
    if (event?.data?.state === 'connected') {
      this.emitter.emit('IMNetStateChange', { state: 'connected' });
    }
    this.logger.info(TIMSdk.EVENT.NET_STATE_CHANGE, event.data);
  }

  [TIMSdk.EVENT.CONVERSATION_LIST_UPDATED](event: any) {
    const conversationList = [];
    if (Array.isArray(event.data)) {
      let sessionId;
      event.data.map((item) => {
        if (item.conversationID.startsWith('C2C')) {
          sessionId = item.conversationID;
          conversationList.push({ sessionId, conversation: item });
        } else {
          sessionId = getSessionIdFromGroupId(item.conversationID?.substring(5), this.emitter);
          conversationList.push({ sessionId, conversation: item });
        }
      });
    }
    this.emitter.emit('conversationListUpdated', { data: conversationList });
  }

  [TIMSdk.EVENT.MESSAGE_RECEIVED](event: { data: TIMSdk.Message[] }) {
    const messageData: Message[] = [];
    if (Array.isArray(event.data)) {
      event.data.map(this.transformWxMessage).forEach((message) => {
        let sessionId;
        // 内部会话sessionId为 C2C+userId
        if (message.conversationType === 'C2C') {
          sessionId = `C2C${message.from}`;
        } else if (
          message.conversationType === '@TIM#SYSTEM' ||
          message.conversationType === 'TIMGroupSystemNoticeElem'
        ) {
          sessionId = 'administrator';
        } else {
          sessionId = getSessionIdFromGroupId(message.conversationID?.substring(5), this.emitter);
        }
        const plainMessage = this.transformOriginMessage(this.transformAdminMessage(message), sessionId);
        messageData.push(plainMessage);
      });
    }
    if (messageData.length > 0) {
      this.messageReceive(messageData);
    }
  }

  [TIMSdk.EVENT.MESSAGE_MODIFIED](event: { data: TIMSdk.Message[] }) {
    this.emitter.emit('onMessageModified', {
      data: map(event.data, (message) => {
        let sessionId;
        if (message.conversationType === 'C2C') {
          sessionId = `C2C${message.from}`;
        } else if (
          message.conversationType === '@TIM#SYSTEM' ||
          message.conversationType === 'TIMGroupSystemNoticeElem'
        ) {
          sessionId = 'administrator';
        } else {
          sessionId = getSessionIdFromGroupId(message.conversationID?.substring(5), this.emitter);
        }
        return { message, sessionId };
      }),
    });
  }

  [TIMSdk.EVENT.MESSAGE_REVOKED](event: { data: TIMSdk.Message[] }) {
    this.emitter.emit('onMessageRevoked', {
      data: map(event.data, (message) => {
        let sessionId;
        if (message.conversationType === 'C2C') {
          sessionId = `C2C${message.from}`;
        } else if (
          message.conversationType === '@TIM#SYSTEM' ||
          message.conversationType === 'TIMGroupSystemNoticeElem'
        ) {
          sessionId = 'administrator';
        } else {
          sessionId = getSessionIdFromGroupId(message.conversationID?.substring(5), this.emitter);
        }
        return { messageId: message.ID, sessionId };
      }),
    });
  }

  async [TIMSdk.EVENT.MESSAGE_READ_RECEIPT_RECEIVED](event: any) {
    const receiptList = [];
    try {
      if (Array.isArray(event.data)) {
        for (const item of event.data) {
          let sessionId;
          const originMessage = (await this.timSdk).findMessage(item.messageID);
          if (originMessage) {
            if (originMessage.conversationType === 'C2C') {
              sessionId = originMessage.conversationID;
              receiptList.push({
                sessionId,
                messageID: item?.messageID ?? '',
                readCount: item?.readCount ?? 0,
                unreadCount: item?.unreadCount ?? 0,
              });
            } else if (originMessage.conversationType === 'GROUP') {
              sessionId = getSessionIdFromGroupId(originMessage.conversationID?.substring(5), this.emitter);
              receiptList.push({
                sessionId,
                messageID: item?.messageID ?? '',
                readCount: item?.readCount ?? 0,
                unreadCount: item?.unreadCount ?? 0,
              });
            }
          }
        }
        this.emitter.emit('onMessageReadReceiptReceived', { data: receiptList });
      }
    } catch (e) {
      this.logger.error('MESSAGE_READ_RECEIPT_RECEIVED ERROR', e);
    }
  }

  /**
   * Message.ID发送前后会变，所以用conversationID+random替代
   */
  private transformOriginMessage<T extends TIMSdk.Message>(message: T, sessionId: string): PlainMessage<T> {
    return {
      ...getPlainMessage(message),
      sessionId,
    };
  }

  private transformAdminMessage(message: TIMSdk.Message) {
    if (message.flow === 'in' && ['admin', 'admin2', 'administrator', '系统消息'].includes(message.from)) {
      return {
        ...message,
        isSystemMessage: true,
        from: 'administrator',
      };
    }
    return message;
  }

  private messageReceive(data: Message[]) {
    if (isArray(data)) {
      const messageData: Exclude<Message, CustomMessage>[] = data.reduce((acc, message) => {
        if (message.type === TIMSdk.TYPES.MSG_CUSTOM) {
          this.handleCustomMessage(message);
          return acc;
        }
        if (isLocal) {
          console.debug('[TIM] messageReceive', data);
        }
        if (message.type === TIMSdk.TYPES.MSG_GRP_SYS_NOTICE) {
          return [...acc, { ...message, sessionId: 'administrator' }];
        }
        return [...acc, message];
      }, [] as Exclude<Message, CustomMessage>[]);
      if (messageData.length > 0) {
        this.emitter.emit('messageReceived', { data: messageData });
      }
    } else {
      this.logger.warn('unexpected message type', data);
    }
  }

  private handleCustomMessage(message: CustomMessage) {
    let payloadData: string = message.payload.data;
    try {
      payloadData = JSON.parse(payloadData);
    } catch (e) {
      if (isError(e) && e.message.indexOf('is not valid JSON') !== -1) {
        this.logger.warn('parse custom message failed', e);
      }
    }
    const messageType = get(payloadData, 'src', '');

    /**
     * 输入状态变化
     */
    if (messageType === CUSTOM_MESSAGE_SRC.TYPING_STATE) {
      if (message.flow === 'in') {
        this.emitter.emit('typingStateChanged', { sessionId: message.sessionId, state: true });
        this.cancelTypingStateChangedDebounce({ sessionId: message.sessionId });
      }
      return;
    }
    if (messageType === CUSTOM_MESSAGE_SRC.MENU_SELECTED) {
      this.emitter.emit('ratingSucceeded', { sessionId: message.sessionId, data: payloadData, message });
      return;
    }
    if (messageType === CUSTOM_MESSAGE_SRC.MEDIA_CHANGE) {
      this.logger.info('media change', `sessionId: ${message.sessionId}`, payloadData);
      // 媒体消息变化，用户端和admin都会发，忽略admin, 只处理用户端，表示对方挂断
      type MediaMessage = {
        sessionId: string;
        content: {
          currentMedia: 'audio' | 'video' | ''; // 后台下发
          mediaStatus?: 'finished' | 'reject' | 'inProgress'; // 前端自定义，对端挂断通过该字段判断
        };
      };
      const mediaStatus = (payloadData as unknown as MediaMessage)?.content?.mediaStatus;
      if (mediaStatus === 'finished') {
        return mediaEndedHandler({ sessionId: message.sessionId }, this.emitter);
      }
      if (mediaStatus === 'inProgress') {
        return mediaStartHandler({ sessionId: message.sessionId }, this.emitter);
      }
      if (mediaStatus === 'reject') {
        return mediaRejectHandler({ sessionId: message.sessionId }, this.emitter);
      }
      if (mediaStatus) {
        this.logger.info('receive unknown media status message', `sessionId: ${message.sessionId}`, mediaStatus);
      }
    }
    // 不显示的消息类型
    if (
      [
        CUSTOM_MESSAGE_SRC.MINI_APP_AUTO,
        CUSTOM_MESSAGE_SRC.WEB,
        CUSTOM_MESSAGE_SRC.MEMBER,
        CUSTOM_MESSAGE_SRC.NO_SEAT_ONLINE,
        CUSTOM_MESSAGE_SRC.TIMEOUT,
        CUSTOM_MESSAGE_SRC.END,
      ].includes(messageType)
    ) {
      return;
    }
    this.logger.warn('receive unknown custom message', message);
    this.emitter.emit('messageReceived', { data: [message] });
  }

  /**
   * 微信公众号、微信客服消息
   * 后台转化成custom message类型,src为1
   * 从内部属性_elements中查找
   * TODO: 建议后续优化为文本消息类型
   */
  private transformWxMessage(data: TIMSdk.Message) {
    if (data.type === TIMSdk.TYPES.MSG_CUSTOM && typeof data.payload.data === 'string') {
      try {
        const { src } = JSON.parse(data.payload.data);
        const element = data._elements.find((item) => item.type !== TIMSdk.TYPES.MSG_CUSTOM);
        if (
          (src === CUSTOM_MESSAGE_SRC.OFFICIAL_ACCOUNT || src === CUSTOM_MESSAGE_SRC.WX_KF) &&
          data._elements.length > 1 &&
          element
        ) {
          const message: TIMSdk.Message = {
            ...data,
            type: element.type,
            payload: element.content,
          };
          return message;
        }
        return data;
      } catch (e) {
        // 忽略解析json失败日志，有客户用自定义消息不是json字符串
        if (isError(e) && e.message.indexOf('is not valid JSON') !== -1) {
          this.logger.warn('parse custom message failed', e);
        }
        return data;
      }
    }
    return data;
  }

  private filterMessageList = (message: TIMSdk.Message) => {
    if (message.type === TIMSdk.TYPES.MSG_GRP_TIP) {
      return false;
    }
    let payloadData: string = message.payload.data;
    try {
      payloadData = JSON.parse(payloadData);
    } catch (e) {
      if (isError(e) && e.message.indexOf('is not valid JSON') !== -1) {
        this.logger.warn('parse custom message failed', e);
      }
    }
    const messageType = get(payloadData, 'src', '') as string;

    return ![
      CUSTOM_MESSAGE_SRC.OFFICIAL_ACCOUNT,
      CUSTOM_MESSAGE_SRC.BACKEND_INTERNAL,
      CUSTOM_MESSAGE_SRC.MINI_APP_AUTO,
      CUSTOM_MESSAGE_SRC.INTERNAL,
      CUSTOM_MESSAGE_SRC.CLIENT_STATE,
      CUSTOM_MESSAGE_SRC.WEB,
      CUSTOM_MESSAGE_SRC.MEMBER,
      CUSTOM_MESSAGE_SRC.NO_SEAT_ONLINE,
      CUSTOM_MESSAGE_SRC.TIMEOUT,
      CUSTOM_MESSAGE_SRC.END,
      CUSTOM_MESSAGE_SRC.MEDIA_CHANGE,
    ].includes(messageType);
  };

  private innerGetMessageList = async (
    sdk: TIMSdk.SDK,
    args: Parameters<TIM['getMessageList']>[0],
  ): ReturnType<TIM['getMessageList']> => {
    const { sessionId, ...params } = args;
    const res = await sdk.getMessageList(params);
    if (res?.data?.messageList && res?.data?.messageList?.length !== 0) {
      await sdk.getMessageReadReceiptList(res.data.messageList);
    }
    this.logger.info('innerGetMessageList', JSON.stringify(res));
    const messageList = res.data.messageList
      .map(this.transformWxMessage)
      .filter(this.filterMessageList)
      .map((message) => {
        const plainMessage = this.transformOriginMessage(this.transformAdminMessage(message), sessionId);
        return plainMessage;
      });
    const response = {
      code: res.code,
      data: {
        ...res.data,
        messageList,
      },
    };
    this.logger.info('innerGetMessageList response', JSON.stringify(response));
    return response;
  };

  private innerGetMessageListV2 = async (
    sdk: TIMSdk.SDK,
    args: Parameters<TIM['getMessageListV2']>[0],
  ): ReturnType<TIM['getMessageListV2']> => {
    const { sessionId, ...params } = args;
    const res = await sdk.getMessageListHopping(params);
    if (res?.data?.messageList && res?.data?.messageList?.length !== 0) {
      await sdk.getMessageReadReceiptList(res.data.messageList);
    }
    this.logger.info('innerGetMessageList', JSON.stringify(res));
    const messageList = res.data.messageList
      .map(this.transformWxMessage)
      .filter(this.filterMessageList)
      .map((message) => {
        const plainMessage = this.transformOriginMessage(this.transformAdminMessage(message), sessionId);
        return plainMessage;
      });
    const response = {
      code: res.code,
      data: {
        ...res.data,
        messageList,
      },
    };
    this.logger.info('innerGetMessageList response', JSON.stringify(response));
    return response;
  };
}
