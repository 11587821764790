import { SessionType } from './session-manager';

export const isCallSessionType = (sessionType: SessionType) =>
  sessionType === 'AudioIncoming' ||
  sessionType === 'CallIncoming' ||
  sessionType === 'CallInternal' ||
  sessionType === 'CallOutgoing' ||
  sessionType === 'IVRCallOutgoing';

export const isChatSessionType = (sessionType: SessionType) =>
  sessionType === 'WebIMIncoming' || sessionType === 'IMIncoming';
