import {
  ChatExtra,
  SessionChangedType,
  SessionEndState,
  SessionManager,
  SessionSummary,
  SessionType,
} from './session-manager';
import { TcccSdk } from '../../tccc';
import { Member } from './member-manager';
import { CreateLogger } from './createLogger';

export const SUPPORT_SESSION_TYPE = [
  'CallIncoming',
  'CallOutgoing',
  'AudioIncoming',
  'CallInternal',
  'IVRCallOutgoing',
  'WebIMIncoming',
  'IMIncoming',
];
export const SUPPORT_SERVER_TYPE = ['staffSeat', 'staffExtensionSeat', 'staffPhoneSeat', 'staffMiniProgramSeat'];

export const checkSessionTypeSupported = (sessionType: string) => SUPPORT_SESSION_TYPE.includes(sessionType);
export const checkServerTypeSupported = (serverType: string) => SUPPORT_SERVER_TYPE.includes(serverType);

// 检查是否需要创建新Session
export const checkSessionCreate = ({
  sdkAppId,
  userId,
  data,
}: {
  sdkAppId: string;
  userId: string;
  data: SessionChangedType;
}) => {
  // 需要清除
  if (
    data.members.length === 0 &&
    data.sessionEndState &&
    SessionManager.getSession({ sdkAppId, userId, sessionId: data.sessionId })
  ) {
    return false;
  }
  return (
    checkSessionTypeSupported(data.sessionType) &&
    data.members
      .filter(
        (member) =>
          // 1. memberId是自己
          member.userId === userId &&
          // 2. 非监听会话
          !member.isMonitor &&
          // 3. serverType为手机/话机/Web等类型
          checkServerTypeSupported(member.serverType),
      )
      // 结束了也不在列表内需清除
      .every(
        (member) =>
          member.callState === 'END' && !SessionManager.getSession({ sdkAppId, userId, sessionId: data.sessionId }),
      )
  );
};

// 检查Session sequence跟heartbeat 是否一致
export const checkSessionSeq = ({
  sdkAppId,
  userId,
  emitter,
  summaryList,
  tabUUID,
}: {
  sdkAppId: string;
  userId: string;
  emitter: TcccSdk;
  summaryList?: SessionSummary[] | null;
  tabUUID: string;
}) => {
  const sessionLogger = CreateLogger('SESSION', {}, emitter.Agent.userInfo);
  const sessionIds: string[] = [];
  if (summaryList && Array.isArray(summaryList) && summaryList.length > 0) {
    summaryList
      .filter(({ SessionType }) => checkSessionTypeSupported(SessionType))
      .forEach(({ SessionID: sessionId, Sequence: sequence }) => {
        // 已经开始了的会话，但是本地没创建，需要从activeSession里面获取
        if (!SessionManager.getSession({ sdkAppId, userId, sessionId })) {
          sessionLogger.debug(sessionId, 'not exist local');
          sessionIds.push(sessionId);
        }
        // 创建了，比对一下sequence是否一致
        else if (SessionManager.getSequence({ sdkAppId, userId, sessionId }) < sequence) {
          sessionLogger.debug(
            sessionId,
            `seq diff with heartbeat ${SessionManager.getSequence({ sdkAppId, userId, sessionId })} < ${sequence}`,
          );
          sessionIds.push(sessionId);
        }
      });
  }
  const sessionList = SessionManager.getSessionList({ sdkAppId, userId });
  if (summaryList === null && Object.keys(sessionList).length > 0) {
    sessionLogger.debug(Object.keys(sessionList).join(', '), ' not exist server');
    // 本地创建了，但是heartbeat没有，需查询activeSession是否已结束
    Object.keys(sessionList).forEach((i) => sessionIds.push(i));
  }
  if (sessionIds.length > 0) {
    sessionLogger.debug('checkSessionSeq', sessionIds);
    emitter.http.request('/ccc/im/getActiveSession').then(({ sessionList }) => {
      const event2017List = sessionList
        .filter((item) => item.sessionType && checkSessionTypeSupported(item.sessionType))
        .map((item) => {
          const members = item.members as unknown as Member[];
          const sessionType = item.sessionType as SessionType;
          const chatExtra: ChatExtra = {
            channelAgentID: item.channelAgentID || '',
            clientChannelName: item.clientChannelName || '',
            closeBy: '',
            endReason: 0,
            imAgentChatType: item.imAgentChatType || 0,
            imGroupId: item.imGroupId || '',
            isIMCallOut: item.isIMCallOut || 0,
            serverType: 'staffSeat',
            sessionId: item.sessionId,
            skillGroupID: '',
          };
          const event2017: SessionChangedType = {
            sessionId: item.sessionId,
            members,
            sequence: item.sequence as number,
            sessionType,
            sessionEndState: item.sessionEndState as unknown as SessionEndState,
            customer: {
              callerLocation: item.userPhoneLocation,
              ivrPress: item.keyPress,
              ivrPressLabel: item.ivrPressLabel || [],
              phone: item.phone,
              protectedPhone: item.protectedPhone,
              skillGroupID: '',
              servingNumber: item.servingNumber,
              servingNumberRemark: '',
              clientData: item.clientData,
              remark: item.remark,
              userId:
                sessionType === 'CallInternal'
                  ? item.members?.find((member) => member.userId !== item.userId)?.userId
                  : item.userId,
              peerSource: item.peerSource,
              avatar: item.avatar,
              nickName: item.wxNick,
              onlineState: typeof item.onLineState !== 'undefined' ? +item.onLineState : 1,
            },
            extra: chatExtra,
          };
          return event2017;
        });
      // 交集部分直接dispatch,内部会忽略相同seq的变化
      event2017List
        .filter((item) => sessionIds.includes(item.sessionId))
        .forEach((item) => {
          sessionLogger.debug(
            'dispatch session change from active session list',
            item.sessionId,
            item.sessionEndState,
            `sequence: ${item.sequence}`,
          );
          if (item.sessionEndState) {
            const sequence = (SessionManager.getSequence({ sdkAppId, userId, sessionId: item.sessionId }) || 0) + 1;
            SessionManager.createSession({ sdkAppId, userId, data: { ...item, sequence }, emitter, tabUUID });
          } else {
            SessionManager.createSession({ sdkAppId, userId, data: item, emitter, tabUUID });
          }
        });

      // 只存在于客户端的，不太可能
      sessionIds
        .filter((sessionId) => !event2017List.some((item) => item.sessionId === sessionId))
        .forEach((sessionId) => {
          const session = SessionManager.getSession({ sdkAppId, userId, sessionId });
          const sequence = SessionManager.getSequence({ sdkAppId, userId, sessionId });
          if (session && sequence) {
            const event2017: SessionChangedType = {
              sessionId,
              members: [],
              sessionEndState: {
                hangupCode: 1,
                hangupSide: 'user',
                hangupText: '',
              },
              extra: session.chatExtraData,
              sequence: sequence + 1,
              sessionType: session.sessionType,
              customer: {},
            };
            sessionLogger.debug(
              'dispatch session change from custom end session',
              sessionId,
              `sequence: ${event2017.sequence}`,
            );
            SessionManager.createSession({ sdkAppId, userId, data: event2017, emitter, tabUUID });
          } else {
            sessionLogger.debug('session only exists locally', sessionId);
          }
        });

      // 只存在服务端，并且sequence不为空
      event2017List
        .filter((item) => !sessionIds.includes(item.sessionId) && item.sequence)
        .forEach((item) => {
          sessionLogger.debug('session only exists server', item.sessionId, `sequence: ${item.sequence}`);
          SessionManager.createSession({ sdkAppId, userId, data: item, emitter, tabUUID });
        });
    });
  }
};
