import { Customer, SessionChangedType, SessionType } from './session-manager';
import { ServerType } from '../../store/sessions';
import { checkServerTypeSupported } from './checkSession';
import { Direction, SessionType as SessionEventType } from '../../constants/sessions';
import { CreateLogger } from './createLogger';
import { TcccSdk } from '../../tccc';
import { filterMember } from './filter-member';

export const setServerType = ({ data, userId }: { data: SessionChangedType; userId: string }): ServerType => {
  const agentMembers = data.members
    .filter((member) => member.userId === userId && member.callState !== 'END')
    .filter((member) => checkServerTypeSupported(member.serverType))
    .sort((a, b) => {
      if (a.serverType === b.serverType) return 0;
      // 开启手机始终接听时，同时存在serverType为staffSeat和staffPhoneSeat的Member，staffSeatMember优先级更高
      if (a.serverType === 'staffSeat') return -1;
      return 1;
    });
  if (agentMembers.length > 0) {
    return agentMembers[0].serverType as ServerType;
  }
  return 'staffSeat';
};

export const setCustomer = ({
  data,
  userId,
  direction,
  sessionType,
}: {
  data: SessionChangedType;
  userId: string;
  direction: Direction;
  sessionType: SessionType;
}): Customer => {
  if (sessionType === 'CallInternal') {
    // 内线时customer逻辑不同，无论是发起方/被叫方，customer都是被叫方
    if (direction === Direction.callOut) {
      return data.customer;
    }
    const customerMember = data.members.find(
      (item) => item.userId !== userId && checkServerTypeSupported(item.serverType),
    );
    if (customerMember) {
      return {
        userId: customerMember.userId,
      };
    }
  }
  return data.customer;
};

export const setDirection = ({
  data,
  userId,
  emitter,
}: {
  data: SessionChangedType;
  userId: string;
  emitter: TcccSdk;
}) => {
  const sessionLogger = CreateLogger('SESSION', { sessionId: data.sessionId, memberId: '' }, emitter.Agent.userInfo);
  const { members } = data;
  if (members.length === 0) {
    sessionLogger.info('session direction empty');
    return Direction.callIn;
  }
  const agentMember = members.filter(
    (member) =>
      member.userId === userId &&
      ['staffSeat', 'staffPhoneSeat', 'miniProgramSeat', 'staffExtensionSeat'].includes(member.serverType),
  );

  if (agentMember.length === 0) {
    // 已经结束了的会话
    return Direction.callIn;
  }

  if (agentMember.length > 1) {
    sessionLogger.debug(`greater than 1 agent member`);
  }

  return agentMember[agentMember.length - 1].direction === 'Incoming' ? Direction.callIn : Direction.callOut;
};

export const setSessionType = (sessionType: string): SessionEventType => {
  if (sessionType === 'CallOutgoing' || sessionType === 'CallIncoming') {
    return SessionEventType.phone;
  }
  if (sessionType === 'CallInternal') {
    return SessionEventType.internal;
  }
  if (sessionType === 'AudioIncoming') {
    return SessionEventType.voip;
  }
  if (sessionType === 'WebIMIncoming' || sessionType === 'IMIncoming') {
    return SessionEventType.im;
  }
  return SessionEventType.phone;
};

export const setTRTCParams = ({
  data,
  userId,
  emitter,
}: {
  data: SessionChangedType;
  userId: string;
  emitter: TcccSdk;
}) => {
  const sessionLogger = CreateLogger('SESSION', { sessionId: data.sessionId, memberId: '' }, emitter.Agent.userInfo);
  const agentMembers = filterMember(data, userId, 'agent', null, emitter);
  if (agentMembers.length !== 0) {
    const trtcParams = agentMembers.find((item) => item.trtcParams && item.serverType === 'staffSeat')?.trtcParams;
    if (trtcParams) {
      return trtcParams;
    }
    sessionLogger.debug('missing trtc params in members');
  } else {
    sessionLogger.debug('agent members empty');
  }
};

export const setHost = ({ data, userId, sdk }: { data: SessionChangedType; userId: string; sdk: TcccSdk }) =>
  filterMember(data, userId, 'agent', null, sdk)
    .filter((member) => member.callState !== 'END')
    .some((member) => member.isHost);
